import { NextSeo } from 'next-seo';
import React, { FC } from 'react';
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';
import { Navbar } from './chakraUIPro/Navbar';
import { Footer } from './chakraUIPro/Footer';
import Waves from './Waves';

interface PageProps extends BoxProps {
  children: React.ReactNode;
  seoOptions?: any;
}

const Page: FC<PageProps> = ({ children, seoOptions, ...props }) => {
  const wavesColor = useColorModeValue('brand.600', 'brand.800');

  return (
    <Box bg='bg-default'>
      <NextSeo title='Smart Parse' description='' {...seoOptions} />
      <Box maxW='8xl' mx='auto' textAlign='left'>
        <Navbar />
      </Box>

      <Box align='left' maxW='7xl' mx='auto' px={8} textAlign='left' {...props}>
        {children}
      </Box>

      <Box position='relative' pb='250px'>
        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
        <Waves color={wavesColor} />
      </Box>

      <Box bgGradient={useColorModeValue('linear(to-b, brand.600, gray.700)', 'linear(to-b, brand.800, brand.700)')}>
        <Box maxW='8xl' mx='auto' p={8} textAlign='left'>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default Page;
